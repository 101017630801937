import React from "react";

const Logo = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
    id="svg5"
    width="1570.675"
    height="204.352"
    version="1.1"
    viewBox="0 0 415.575 54.068"
  >
    <g id="layer2" display="inline" transform="translate(-56.041 -49.081)">
      <g
        id="text"
        fill="#333"
        strokeWidth="0.265"
        aria-label="RESUMEP P"
        style={{}}
        fontFamily="Open Sans"
        fontSize="73.378"
        fontWeight="800"
        letterSpacing="-0.661"
      >
        <path
          id="path67"
          d="M73.203 49.834q7.13 0 11.86 1.755 4.729 1.72 7.094 5.16 2.4 3.44 2.4 8.563 0 3.189-1.075 5.804-1.075 2.616-3.081 4.694-1.97 2.042-4.73 3.583l15.264 22.823H84.883L73.776 83.155h-3.582v19.06H56.04v-52.38zm-.215 10.713h-2.794v11.966h2.65q3.261 0 5.34-1.433 2.077-1.433 2.077-5.123 0-2.544-1.72-3.977-1.72-1.433-5.553-1.433z"
        ></path>
        <path
          id="path69"
          d="M136.138 102.216h-31.1V49.834h31.1v11.358h-16.947v8.24h15.693V80.79h-15.693v9.889h16.947z"
        ></path>
        <path
          id="path71"
          d="M178.901 86.308q0 4.263-2.15 8.097-2.15 3.798-6.7 6.163-4.514 2.364-11.68 2.364-3.583 0-6.306-.322-2.687-.323-5.016-1.04-2.329-.752-4.837-1.934V87.024q4.264 2.15 8.6 3.296 4.335 1.111 7.846 1.111 2.114 0 3.44-.501 1.361-.502 2.006-1.362.645-.896.645-2.042 0-1.362-.967-2.293-.932-.968-3.01-2.007-2.078-1.039-5.482-2.58-2.902-1.36-5.267-2.794-2.329-1.433-4.013-3.26-1.648-1.864-2.544-4.336-.895-2.472-.895-5.84 0-5.052 2.472-8.455 2.508-3.44 6.95-5.16 4.48-1.72 10.32-1.72 5.123 0 9.208 1.147 4.12 1.146 7.38 2.615l-4.335 10.928q-3.368-1.54-6.628-2.436-3.26-.932-6.055-.932-1.828 0-3.01.466-1.147.43-1.72 1.182-.537.753-.537 1.72 0 1.218.931 2.186.968.967 3.189 2.114 2.257 1.11 6.127 2.902 3.833 1.72 6.52 3.726 2.688 1.97 4.085 4.765 1.433 2.76 1.433 6.844z"
        ></path>
        <path
          id="path73"
          d="M230.873 49.834v31.53q0 6.592-2.508 11.465-2.508 4.836-7.525 7.488-5.016 2.615-12.575 2.615-10.82 0-16.518-5.553-5.696-5.554-5.696-15.693V49.834h14.224v30.741q0 5.553 2.078 8.062 2.078 2.508 6.126 2.508 2.903 0 4.73-1.075 1.827-1.111 2.651-3.476.86-2.364.86-6.09v-30.67z"
        ></path>
        <path
          id="path75"
          d="M264.034 102.216L253.32 64.488h-.323q.144 1.469.287 3.977.18 2.508.322 5.41.144 2.902.144 5.59v22.75h-12.54v-52.38h18.846l10.927 37.19h.287l10.713-37.19h18.882v52.382H287.86V79.249q0-2.472.072-5.302.107-2.867.215-5.375.143-2.544.25-4.013h-.322l-10.57 37.657z"
        ></path>
        <path
          id="path77"
          d="M342.59 102.216h-31.1V49.834h31.1v11.358h-16.947v8.24h15.693V80.79h-15.693v9.889h16.947z"
        ></path>
        <path
          id="path79"
          d="M369.23 49.834q9.96 0 14.905 4.335 4.98 4.335 4.98 12.325 0 3.583-1.039 6.88-1.003 3.296-3.332 5.875-2.293 2.58-6.127 4.085-3.797 1.505-9.387 1.505h-3.977v17.377h-14.152V49.834zm-.322 11.43h-3.655v12.038h2.795q1.827 0 3.404-.61 1.576-.644 2.508-2.042.967-1.433.967-3.797 0-2.473-1.469-4.013-1.469-1.577-4.55-1.577z"
        ></path>
        <path
          id="path81"
          d="M451.73 49.834q9.961 0 14.905 4.335 4.98 4.335 4.98 12.325 0 3.583-1.038 6.88-1.004 3.296-3.333 5.875-2.293 2.58-6.126 4.085-3.798 1.505-9.387 1.505h-3.977v17.377H433.6V49.834zm-.322 11.43h-3.654v12.038h2.794q1.827 0 3.404-.61 1.576-.644 2.508-2.042.967-1.433.967-3.797 0-2.473-1.469-4.013-1.469-1.577-4.55-1.577z"
        ></path>
      </g>
    </g>
    <g id="layer1" display="inline" transform="translate(-56.041 -49.081)">
      <g
        id="star"
        display="inline"
        transform="matrix(.00878 0 0 -.00938 383.424 103.153)"
      >
        <path
          id="path14"
          fill="#333"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M2347.72 4958.9l1423.73 734.18L3272.99 3778l-1415.67-613.64 490.4 1794.54"
        ></path>
        <path
          id="path16"
          fill="none"
          stroke="#333"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="30"
          d="M2347.72 4958.9l1423.73 734.18L3272.99 3778l-1415.67-613.64z"
        ></path>
        <path
          id="path18"
          fill="#333"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M2465.88 1242.01l1463.98 469.32L5463.54 385.148 3916.96 15 2465.88 1242.01"
        ></path>
        <path
          id="path20"
          fill="none"
          stroke="#333"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="30"
          d="M2465.88 1242.01l1463.98 469.32L5463.54 385.148 3916.96 15z"
        ></path>
        <path
          id="path22"
          fill="#333"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M15 3315.03l1316.15 644.53 1623.77-1404.68-1409.72-534.09L15 3315.03"
        ></path>
        <path
          id="path24"
          fill="none"
          stroke="#333"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="30"
          d="M15 3315.03l1316.15 644.53 1623.77-1404.68-1409.72-534.09z"
        ></path>
        <path
          id="path26"
          fill="#1a1a1a"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M1545.2 2020.79l1409.72 534.09-532.25-2046.189-1398.6-396.289L1545.2 2020.79"
        ></path>
        <path
          id="path28"
          fill="#333"
          stroke="#333"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="30"
          d="M1545.2 2020.79l1409.72 534.09-532.25-2046.189-1398.6-396.289z"
        ></path>
        <path
          id="path30"
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          d="M1331.15 3959.56L3272.99 3778l498.46 1915.08 644.31-2021.99 1763.83-164.81L4815.18 2417.9l648.36-2032.752L3929.86 1711.33 2422.67 508.691l532.25 2046.189-1623.77 1404.68"
        ></path>
        <path
          id="path32"
          fill="none"
          stroke="#1a1a1a"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="30"
          d="M1331.15 3959.56L3272.99 3778l498.46 1915.08 644.31-2021.99 1763.83-164.81L4815.18 2417.9l648.36-2032.752L3929.86 1711.33 2422.67 508.691l532.25 2046.189z"
        ></path>
      </g>
    </g>
  </svg>
);

export default Logo;
